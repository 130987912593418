<template>
  <div class="row">
    <div class="col-12 col-lg-6 c">
      <div class="card card-body">
        <img
          :src="require('@/assets/images/home.png')"
          style="width: 100%; cursor: pointer"
          v-b-modal.modal-1
        />
      </div>
    </div>
    <div class="col-12"></div>
    <div class="col-12 col-lg-6 c">
      <div class="row g">
        <div class="col-12 col-lg-4 g">
          <div class="card text-center a">
            <div class="card-body border">
              <img
                @click="$router.push('/settings/general')"
                class="g"
                :src="require('@/assets/images/start.png')"
                style="width: 100px"
                alt=""
              />
              <h5>ابدأ الآن</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 g">
          <div class="card text-center a">
            <div class="card-body border">
              <img
                class="g"
                :src="require('@/assets/images/services.png')"
                style="width: 100px"
                alt=""
              />
              <h5>خدمات اخرى</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 g">
          <div class="card text-center a">
            <div class="card-body border">
              <img
                class="g"
                :src="require('@/assets/images/help.png')"
                style="width: 100px"
                alt=""
              />
              <h5>تواصل معنا</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="شرح استخدام الموقع"
      ok-only
      ok-title="اغلاق"
      size="lg"
    >
      <iframe
        style="width: 100%"
        height="444"
        src="https://www.youtube.com/embed/SEa6wrFUTt8"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <div class="col-12 text-center">
        <a
          href="https://www.youtube.com/watch?v=SEa6wrFUTt8"
          class="text-danger"
          target="_blank"
        >
          إذا لم يظهر الشرح إضغط هنا
        </a>
      </div>
    </b-modal>
  </div>
</template>

<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import { BModal } from "bootstrap-vue";

export default {
  components: {
    BModal,
  },
  data() {
    return {
      user: user,
    };
  },
};
</script>

<style scoped>
.a div,
.a {
  border-radius: 8px !important;
}
.a:hover {
  cursor: pointer;
  border: solid 2px lightgreen;
}
</style>
